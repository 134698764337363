/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import MDBadge from "components/MDBadge";
import formatDateAndTime from "util/formatDateAndTime";
import { useMaterialUIController } from "context";
export default function data(transactions, activemerchant) {
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    return {
        columns: [
            { Header: "order id", accessor: "orderId", align: "left" },
            { Header: "time", accessor: "time", align: "left" },
            { Header: "date", accessor: "date", align: "left" },
            { Header: "merchant", accessor: "merchant", align: "left" },
            { Header: "gateway", accessor: "gateway", align: "left" },
            { Header: "amount", accessor: "amount", align: "right" },
            { Header: "status", accessor: "status", align: "center" },
            { Header: "UTR", accessor: "utr", width: "20%", },
            { Header: "TXID", accessor: "txid", width: "20%", },
        ],

        rows: transactions && transactions.map((item, index) => ({
            orderId: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item?.merchant_ref_no || ''}
                </MDTypography>
            ),
            time: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && formatDateAndTime(item?.transaction_date).formattedTime || ''}
                </MDTypography>
            ),
            date: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && formatDateAndTime(item.transaction_date).formattedDate || ''}
                </MDTypography>
            ),
            merchant: (
                <MDTypography variant="caption" textTransform="uppercase" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item?.business_name || activemerchant}
                </MDTypography>
            ),
            gateway: (
                <MDTypography variant="caption" textTransform="uppercase" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item?.gateway || ''}
                </MDTypography>
            ),
            amount: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item?.amount || ''}
                </MDTypography>
            ),
            status: item && (
                <MDBox ml={-1}>
                    <MDBadge badgeContent={item?.status === "IN-PROCESS" ? "PENDING" : item?.status === "fail" ? "FAILED" : item?.status} color={item?.status === "Success" || item?.status === "success" || item?.status === "SUCCESS" ? "success" : item?.status === "IN-PROCESS" || item.status === "pending" ? "warning" : item?.status === "PENDING" ? "warning" : item.status === "expired" ? "info" : "error"} variant="gradient" size="sm" />
                </MDBox>
            ),
            utr: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item?.utr || ''}
                </MDTypography>
            ),
            txid: (
                <MDTypography variant="caption" color={darkMode ? "white" : "black"} fontWeight="medium">
                    {item && item?.transactionId || ''}
                </MDTypography>
            ),
        }))
    }
}
