/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useEffect, useState } from "react";
// @mui material components
import Card from "@mui/material/Card";
// import FilterSVG from "assets/images/filter.svg"
// import DownloadSVG from "assets/images/download.svg"
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
// import Grid from "@mui/material/Grid";
// import MDButton from "components/MDButton";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
// Data
import { CSVDownload } from "react-csv";
import data from "layouts/dashboard/components/Transactions/data";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useConfig } from "../../../../config"
import { useUser } from "ZustandState/useUser";
import { useSandbox } from "ZustandState/useSanbox";
import formatDateAndTime from "util/formatDateAndTime";
// import MDButton from "components/MDButton";
import { toast } from "react-toastify";
import BeatLoader from "react-spinners/BeatLoader";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
// import DarkSVG from "assets/images/downloadDark.svg"
import { useMaterialUIController } from "context";
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { Grid } from "@mui/material";
function Transactions({ recieveStatsFunc }) {
    const [controller] = useMaterialUIController()
    const { darkMode } = controller
    const { apiUrl } = useConfig()
    const adminEmail = localStorage.getItem('admin_email');
    const adminApiKey = localStorage.getItem('admin_apiKey');
    const user = useUser((state) => state.user)
    const sandbox = useSandbox((state) => state.sandbox)
    const [transactions, setTransactions] = useState([])
    const [totalTransaction, setTotaltransaction] = useState([])
    const [page, setPage] = useState(0)
    const [check, setCheck] = useState("all")
    const [currentStatus, setCurrentStatus] = useState("")
    const [reload, setReload] = useState(true)
    const [isGeneratebtn, setIsGeneratebtn] = useState(true)
    const [isCsvReady, setIsCsvReady] = useState(false)
    const [csvCompleteData, setCsvCompleteData] = useState([])
    const [menu, setMenu] = useState(null);
    const openMenu = ({ currentTarget }) => setMenu(currentTarget);
    const closeMenu = () => setMenu(null);
    const [activeMerchant, setActiveMerchant] = useState('all')
    const [merchantInfo, setMerchantInfo] = useState([])
    const { columns, rows } = data(transactions, activeMerchant !== "all" ? merchantInfo.find(m => m.email_id === activeMerchant).business_name : "");
    const [downloadReady, setDownloadReady] = useState([])
    let limit = 10;
    const formikRef = React.createRef();

    const handleResetForm = () => {
        if (formikRef.current) {
            formikRef.current.resetForm();
        }
    };
    const initialSearchValues = {
        emailId: adminEmail || '',
        apiKey: adminApiKey || '',
        utr: '',
    };
    const initialDateValues = {
        emailId: adminEmail || '',
        email_Id: activeMerchant,
        apiKey: adminApiKey || '',
        start_date: '',
        end_date: ''
    };
    const [formikValues, setFormikValues] = useState(initialDateValues);
    const renderMenu = (
        <Menu
            id="simple-menu"
            anchorEl={menu}
            anchorOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            open={Boolean(menu)}
            onClose={closeMenu}
        >
            <MenuItem onClick={() => {

                setMenu(null);
                setCsvCompleteData([])
                setCheck("all")
                setCurrentStatus("")
                setPage(0)
                setIsCsvReady(false)
                setIsGeneratebtn(true)
                setReload(!reload)
                formikValues.start_date !== "" && formikValues.end_date !== "" && setFormikValues({ ...formikValues, getStatus: false, email_Id: activeMerchant, start_date: "", end_date: "" })
                handleResetForm()

            }}>
                All
            </MenuItem>
            <MenuItem onClick={() => {
                setCurrentStatus("success")
                setPage(0)
                setMenu(null);
                setCsvCompleteData([])
                setCheck("status")
                setIsCsvReady(false)
                setIsGeneratebtn(true)
                formikValues.start_date !== "" && formikValues.end_date !== "" ? handleDateSearch(formikValues, "success") : filterSearch("success")
                // handleResetForm()
            }}>Success</MenuItem>
            <MenuItem onClick={() => {
                setCurrentStatus("IN-PROCESS")
                setPage(0)
                setMenu(null);
                setCsvCompleteData([])
                setCheck("status")
                setIsCsvReady(false)
                setIsGeneratebtn(true)
                formikValues.start_date !== "" && formikValues.end_date !== "" ? handleDateSearch(formikValues, "IN-PROCESS") : filterSearch("IN-PROCESS")

                // handleResetForm()
            }}>Pending</MenuItem>
            <MenuItem onClick={() => {
                setCurrentStatus("failed")
                setPage(0)
                setMenu(null);
                setCsvCompleteData([])
                setCheck("status")
                setIsCsvReady(false)
                setIsGeneratebtn(true)
                formikValues.start_date !== "" && formikValues.end_date !== "" ? handleDateSearch(formikValues, "failed") : filterSearch("failed")
                // handleResetForm()
            }}>Failed</MenuItem>
            <MenuItem onClick={() => {
                setCurrentStatus("expired")
                setPage(0)
                setMenu(null);
                setCsvCompleteData([])
                setCheck("status")
                setIsCsvReady(false)
                setIsGeneratebtn(true)
                formikValues.start_date !== "" && formikValues.end_date !== "" ? handleDateSearch(formikValues, "expired") : filterSearch("expired")
                // handleResetForm()
            }}>Expired</MenuItem>
        </Menu>
    );

    //on change of active merchant get stats of activeMerchant
    const getActiveMerchantStats = async () => {
        try {
            let body = {
                emailId: adminEmail || '',
                apiKey: adminApiKey || '',
                email_Id: activeMerchant
            }
            if (body.emailId === '' || body.apiKey === '' || body.email_Id === "all") {
                return;
            }

            const response = await fetch(`${apiUrl}/admin/getlast24hourdata`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            recieveStatsFunc(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }

    // With this we can find a single transaction by utr globally over all merchant
    const handleSearch = async (values, { setSubmitting }) => {
        try {
            if (values.emailId === '' || values.apiKey === '' || values.utr === '') {
                return;
            }
            const response = await fetch(`${apiUrl}/admin/getMerchantTransactionByUtr`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            })

            if (!response) return;
            const res = await response.json()
            if (res.responseCode !== 200) return;
            setTransactions(res.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    // with this we can get all the merchant info for dropdown 
    const getAllMerchant = async () => {
        let body = {
            emailId: adminEmail || '',
            apiKey: adminApiKey || '',
        }
        try {
            const response = await fetch(`${apiUrl}/admin/getallmerchantsinfo`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            const res = await response.json()
            if (res.responseCode !== 200) return;
            setMerchantInfo(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    // with this we get date wise transaction of any merchant,this endpoint doesnt have pagination on backend
    const handleDateSearch = async (values, status = "") => {
        try {
            if (values.start_date === "" || values.end_date === "" || values.emailId === "" || values.apiKey === "") {
                return;
            }
            if (values.end_date < values.start_date || values.start_date > Date.now()) {
                toast.error('Invalid Date Range!')
                return;
            }
            if (status !== "" && activeMerchant === "all") {
                values.email_Id = "allwithstatus"
                values.status = status
            } else if (status !== "" && activeMerchant !== "all") {
                values.email_Id = activeMerchant
                values.status = status
                values.getStatus = true
            }
            setCheck("date")
            setIsCsvReady(false)
            setCsvCompleteData([])
            const response = await fetch(`${apiUrl}/admin/getUserTransactionsByDate`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values)
            })
            const res = await response.json()
            if (res.responseCode !== 200) {
                setTransactions([])
                setIsGeneratebtn(true)
                return;
            }
            setTransactions(res.responseData)
            setIsGeneratebtn(true)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    // when currentStatus === "" and activeMerchant === "all" for table
    const getAllTransactions = async () => {
        let body = {
            emailId: adminEmail || '',
            apiKey: adminApiKey || '',
            limit,
            skip: limit * page
        }
        try {
            const response = await fetch(`${apiUrl}/admin/getAllMerchantTransactions`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            const res = await response.json()
            if (res.responseCode !== 200) return;
            setTotaltransaction(res?.responseData)
            setTransactions(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    // when currentStatus === "" and activeMerchant === "all" for csv
    const getAllTransactionsForCsv = async (body) => {
        try {
            const response = await fetch(`${apiUrl}/admin/getAllMerchantTransactions`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            const res = await response.json()
            if (res.responseCode !== 200) return;
            return res?.responseData
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    // when currentstatus ==="" and for single merchant for table (finding all the transaction of single merchant)
    const getSingleMerchantAllTransaction = async () => {
        let body = {
            emailId: adminEmail || '',
            apiKey: adminApiKey || '',
            email_Id: activeMerchant,
            limit,
            skip: limit * page
        }
        try {
            const response = await fetch(`${apiUrl}/admin/getUserTransactions`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            const res = await response.json()
            if (res.responseCode !== 200) return;
            setTotaltransaction(res?.responseData)
            setTransactions(res?.responseData)
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    // when currentstatus ==="" and for single merchant for csv (finding all the transaction of single merchant)
    const getSingleMerchantAllTransactionForCsv = async (body) => {
        try {
            const response = await fetch(`${apiUrl}/admin/getUserTransactions`, {
                method: 'POST',
                headers: {
                    //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            const res = await response.json()
            if (res.responseCode !== 200) return;
            return res?.responseData
        } catch (err) {
            console.log("Error Fetching Transactions: ", err)
        }
    }
    // when we are finding transactions of any mercant by status (success, IN-PROCESS,failed) for table
    const filterSearch = async (status) => {
        setCurrentStatus(status)
        setCheck("status")
        let body = {
            emailId: adminEmail || '',
            apiKey: adminApiKey || '',
            email_Id: activeMerchant,
            status,
            limit,
            skip: limit * page
        }
        if (body.emailId === '' || body.apiKey === '') {
            return;
        }
        if (body.email_Id === 'all') {
            let newBody = {
                emailId: adminEmail || '',
                apiKey: adminApiKey,
                status,
                limit,
                skip: limit * page
            }
            try {
                const response = await fetch(`${apiUrl}/admin/getalltransactionsbystatus`, {
                    method: 'POST',
                    headers: {
                        //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(newBody)
                })

                const res = await response.json()
                if (res.responseCode !== 200) {
                    setTransactions([])
                    return;
                }
                setTotaltransaction(res?.responseData)
                setTransactions(res?.responseData)
            } catch (err) {
                console.log("Error Fetching Transactions: ", err)
            }
        } else {
            try {
                const response = await fetch(`${apiUrl}/admin/getUserTransactionsByStatus`, {
                    method: 'POST',
                    headers: {
                        //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body)
                })

                const res = await response.json()
                if (res.responseCode !== 200) {
                    setTransactions([])
                    return;
                }
                setTotaltransaction(res?.responseData)
                setTransactions(res?.responseData)
            } catch (err) {
                console.log("Error Fetching Transactions: ", err)
            }
        }

    }
    // when we are finding transactions of any mercant by status (success, IN-PROCESS,failed) for csv
    const filterSearchForCsv = async (body) => {
        if (body.email_Id === "all") {
            try {
                const response = await fetch(`${apiUrl}/admin/getalltransactionsbystatus`, {
                    method: 'POST',
                    headers: {
                        //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body)
                })

                const res = await response.json()
                if (res.responseCode !== 200) {
                    // setTransactions([])
                    return;
                }
                return res.responseData
            } catch (err) {
                console.log("Error Fetching Transactions: ", err)
            }
        } else {
            try {
                const response = await fetch(`${apiUrl}/admin/getUserTransactionsByStatus`, {
                    method: 'POST',
                    headers: {
                        //   'Authorization': 'Bearer YOUR_ACCESS_TOKEN',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body)
                })

                const res = await response.json()
                if (res.responseCode !== 200) {
                    // setTransactions([])
                    return;
                }
                return res.responseData
            } catch (err) {
                console.log("Error Fetching Transactions: ", err)
            }
        }

    }

    const generateCsv = async (type) => {
        setIsGeneratebtn(false)
        let csvPage = 0
        // getting all the transactions of all the merchant
        if (activeMerchant === "all" && currentStatus === "" && check === "all") {
            let responseData = true
            do {
                let body = {
                    emailId: adminEmail || '',
                    apiKey: adminApiKey || '',
                    limit: 500,
                    skip: 500 * csvPage
                }
                await getAllTransactionsForCsv(body).then((res) => {
                    if (res.length === 0) responseData = false;
                    csvCompleteData.push(...res)
                })
                csvPage++
            } while (responseData)
        } // getting all the transaction of single merchant , if currentStatus === "" and active merchant has something then we have to find all the transaction of that merchant
        else if (currentStatus === "" && activeMerchant !== "all" && check !== "date") {
            let responseData = true
            do {
                let body = {
                    emailId: adminEmail || '',
                    apiKey: adminApiKey || '',
                    email_Id: activeMerchant,
                    limit: 500,
                    skip: 500 * csvPage
                }
                await getSingleMerchantAllTransactionForCsv(body).then((res) => {
                    if (res.length === 0) responseData = false;
                    csvCompleteData.push(...res)
                })
                csvPage++
            } while (responseData)
        }
        else if (check === "status") {
            let responseData = true
            do {
                let body = {
                    emailId: adminEmail || '',
                    apiKey: adminApiKey || '',
                    email_Id: activeMerchant,
                    status: type,
                    limit: 500,
                    skip: 500 * csvPage
                }

                await filterSearchForCsv(body).then((res) => {
                    if (res.length === 0) responseData = false;
                    csvCompleteData.push(...res)
                })
                csvPage++
            } while (responseData)
        }
        else if (check === "date") {
            csvCompleteData.push(...transactions)
        }
        else {
            toast.error("Something is wrong ! Please Select a merchant ")
            setIsGeneratebtn(true)
            return
        }
        // Now that you have all the data in csvCompleteData, remove duplicates based on the _id field.
        const uniqueCsvCompleteData = csvCompleteData.filter((value, index, self) => {
            const firstIndex = self.findIndex((item) => item._id === value._id);
            return index === firstIndex;
        });
        // Set csvCompleteData to the unique data.
        setCsvCompleteData(uniqueCsvCompleteData)
        setDownloadReady([
            ["Time", "Date", "Merchant", "Gateway", "Amount", "status", "UTR", "TXID"],
            ...csvCompleteData.map(({ transaction_date, amount, business_name, gateway, status, utr, transactionId }) => [
                formatDateAndTime(transaction_date).formattedTime || '',
                formatDateAndTime(transaction_date).formattedDate || '',
                business_name ? business_name : merchantInfo.find(m => m.email_id === activeMerchant)?.business_name,
                gateway,
                amount || '',
                status ? status === "IN-PROCESS" ? "pending" : status === "fail" ? "failed" : status.toLowerCase() : '',
                utr || '',
                transactionId || ''
            ]),
        ])
        setIsCsvReady(true)
    }


    useEffect(() => {
        handleResetForm()
        if (activeMerchant !== "all") {
            getActiveMerchantStats()
        }
        else if (activeMerchant === "all") {
            recieveStatsFunc("all")
        }
    }, [activeMerchant, sandbox])


    useEffect(() => {
        if (activeMerchant !== 'all' && currentStatus === "") {
            getSingleMerchantAllTransaction()
        }
        else if (activeMerchant === 'all' && currentStatus === "") {
            getAllTransactions()
        }
        else if (currentStatus !== "all") {
            filterSearch(currentStatus)
        }
    }, [activeMerchant, page, reload, user, sandbox])

    useEffect(() => {
        if (merchantInfo.length === 0) {
            getAllMerchant()
        }
        if (check === "all" && activeMerchant === "all") {
            getAllTransactions()
        }
        else if (check === "status") {
            filterSearch(currentStatus)
        }
    }, [page, reload, user, sandbox])

    return (

        <Card>
            <MDBox display={'flex'} flexDirection={'column'}>
                <MDBox display="flex" justifyContent="space-between" alignItems="center" p={1}>

                    <Grid container alignItems="center" p={1}>
                        <Grid item xl={4}>
                            <MDBox ml={1} alignItems="center">
                                <MDTypography variant="h6" gutterBottom>
                                    {check === "all" ? 'ALL ' : check === "date" ? 'DATE WISE ' : currentStatus.toUpperCase()} TRANSACTIONS
                                </MDTypography>
                            </MDBox>
                        </Grid>
                        <Grid item xl={8}>
                            <Grid container>
                                <Grid item xl={6} sm={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xl={6} xs={5.5} sm={6}>
                                            {/* selectMerchant */}
                                            <Formik
                                                initialValues={{ active_merchant: '' }}
                                            >
                                                {({ setFieldValue }) => (
                                                    <Form>
                                                        <MDBox mb={0.5} >
                                                            <MDTypography variant="caption" color="text" ml={1}>
                                                                Select Merchant
                                                            </MDTypography>
                                                            <Field
                                                                type="text"
                                                                select
                                                                size="large"
                                                                as={MDInput}
                                                                fullWidth
                                                                value={activeMerchant}
                                                                onChange={(e) => {
                                                                    e.target.value !== "" && setActiveMerchant(e.target.value); setPage(0); setCurrentStatus(''); setCheck('all'); setCsvCompleteData([]); setIsGeneratebtn(true);
                                                                    setIsCsvReady(false); setFormikValues({ ...formikValues, email_Id: activeMerchant, start_date: "", end_date: "" })

                                                                }}
                                                                name="active_merchant"
                                                                InputProps={{
                                                                    classes: { root: darkMode ? "select-input-styles-darkMode" : "select-input-styles" },
                                                                }}
                                                            >

                                                                <MenuItem value="">Select Merchant</MenuItem>
                                                                <MenuItem value="all">All</MenuItem>
                                                                {merchantInfo && merchantInfo.map((merchant, index) => (
                                                                    <MenuItem key={index} value={merchant.email_id}>
                                                                        {merchant.business_name?.toUpperCase()}
                                                                    </MenuItem>
                                                                ))}
                                                            </Field>
                                                        </MDBox>


                                                    </Form>
                                                )}
                                            </Formik>
                                        </Grid>
                                        <Grid item xl={6} xs={6.5} sm={6}>
                                            {/* search input */}
                                            <Formik
                                                initialValues={initialSearchValues}
                                                onSubmit={handleSearch}
                                            >
                                                {({ isSubmitting, setFieldValue }) => (
                                                    <Form>
                                                        <MDBox mb={0.5}>
                                                            <MDTypography variant="caption" color="text" ml={1}>
                                                                Search by UTR or TXID
                                                            </MDTypography>
                                                            <MDBox>
                                                                <Grid container>
                                                                    <Grid item xl={10} lg={11} md={11} sm={11} xs={11}>
                                                                        <Field
                                                                            onChange={(e) => {
                                                                                setFieldValue('utr', e.target.value)
                                                                                if (initialSearchValues.utr === '') {
                                                                                    setTransactions(totalTransaction)
                                                                                }
                                                                            }}
                                                                            type="text" as={MDInput} name="utr" fullWidth />
                                                                    </Grid>
                                                                    <Grid item xl={2} lg={1} md={1} sm={1} xs={1}>
                                                                        <IconButton type="submit">
                                                                            <SearchIcon color={darkMode ? "white" : "black"} />
                                                                        </IconButton>
                                                                    </Grid>
                                                                </Grid>


                                                            </MDBox>
                                                            <MDTypography color="error" variant="caption" >
                                                                <ErrorMessage name="utr" component="div" />
                                                            </MDTypography>
                                                        </MDBox>
                                                    </Form>
                                                )}
                                            </Formik>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xl={4.5} sm={12} xs={12} >
                                    {/* date input */}
                                    <Formik
                                        initialValues={initialDateValues}
                                        innerRef={formikRef}
                                    >
                                        {({ isSubmitting, setFieldValue, values }) => (
                                            <Form>
                                                <Grid container spacing={1}>
                                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                                        <MDBox>
                                                            <MDTypography variant="caption" color="text" ml={1}>
                                                                Start date
                                                            </MDTypography>
                                                            <Field
                                                                size="large"
                                                                onChange={async (e) => {
                                                                    await setFieldValue('start_date', e.target.value)
                                                                    const newFormikValues = { ...formikValues, start_date: e.target.value, email_Id: activeMerchant };
                                                                    setFormikValues(newFormikValues);
                                                                    setPage(0)
                                                                    handleDateSearch(newFormikValues);
                                                                }}
                                                                type="date" as={MDInput} fullWidth name="start_date"
                                                            />
                                                            <MDTypography color="error" variant="caption" >
                                                                <ErrorMessage name="start_date" component="div" />
                                                            </MDTypography>
                                                        </MDBox>
                                                    </Grid>
                                                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                                        <MDBox>
                                                            <MDTypography variant="caption" color="text" ml={1}>
                                                                End date
                                                            </MDTypography>
                                                            <Field
                                                                size="large"
                                                                onChange={async (e) => {
                                                                    await setFieldValue('end_date', e.target.value)
                                                                    const newFormikValues = { ...formikValues, end_date: e.target.value, email_Id: activeMerchant };
                                                                    setFormikValues(newFormikValues);
                                                                    setPage(0)
                                                                    handleDateSearch(newFormikValues);
                                                                }}
                                                                type="date" as={MDInput} fullWidth name="end_date" />
                                                            <MDTypography color="error" variant="caption" >
                                                                <ErrorMessage name="end_date" component="div" />
                                                            </MDTypography>
                                                        </MDBox>
                                                    </Grid>
                                                </Grid>
                                            </Form>
                                        )}
                                    </Formik>
                                </Grid>

                                <Grid item xl={1.5} sm={12} xs={12}>
                                    <Grid container alignItems="center" spacing={1} >
                                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                            {/* filter */}
                                            <MDBox mb={2}>
                                                <MDBox mt={3} ml={1} px={2}>
                                                    <TuneOutlinedIcon sx={{ cursor: "pointer", fontWeight: "bold" }} onClick={openMenu} color={darkMode ? "white" : "black"} fontSize="medium" />
                                                </MDBox>
                                                {renderMenu}
                                            </MDBox>
                                        </Grid>
                                        <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                                            {/* csv */}

                                            {/* Export Button Start */}
                                            {
                                                isGeneratebtn === true && (
                                                    <MDBox onClick={() => generateCsv(check === "all" ? check : check === "date" ? "date" : currentStatus)} display={'flex'} flexDirection={'column'}>
                                                        <FileDownloadOutlinedIcon sx={{ cursor: "pointer", fontWeight: "bold" }} color={darkMode ? "white" : "black"} fontSize="medium" />
                                                    </MDBox>
                                                )

                                            }
                                            {isGeneratebtn === false && isCsvReady === false &&
                                                <MDTypography mt={1.5} variant="caption" color="info">
                                                    <BeatLoader
                                                        color="#36d7b7"
                                                        cssOverride={{}}
                                                        size={10}
                                                    />
                                                </MDTypography>
                                            }
                                            {
                                                isCsvReady ? (<CSVDownload filename={`${activeMerchant !== "all" ? merchantInfo.find(m => m.email_id === activeMerchant).business_name : "All-Transaction"}.csv`} onComplete={() => setIsCsvReady(false)} data={downloadReady}>
                                                </CSVDownload>) : (
                                                    null
                                                )
                                            }

                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* <MDBox alignItems="center" color="text" px={2} display={'flex'} flexDirection={'row'}>
                    </MDBox> */}
                </MDBox>
                <MDBox>
                    <DataTable
                        table={{ columns, rows }}
                        showTotalEntries={false}
                        isSorted={false}
                        noEndBorder
                        entriesPerPage={false}
                        pages={page}
                        pageSetter={setPage}
                        checking={check}
                    />
                </MDBox>
            </MDBox>
        </Card>

    );
}

Transactions.propTypes = {
    recieveStatsFunc: PropTypes.func
}
export default Transactions;

